import React, { useState } from "react"
import { Container, Row, Col, Fade } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// Style
import './PropertyValuation.scss';
import PropertyValuationLink from './PropertyValuationLink';

import { inViewOptions, bgImageZoom, delayItemsFromBottom, fadeInFromBottom } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PropertyValuation = (props) => {
  const [ReadMoreText, ReadMoreLess] = useState(false);

  const ReadMore = () => {
    ReadMoreLess(!ReadMoreText)
  }
 
  let background = {};
  if (props.Background_Image.url) {
    background = { backgroundImage: `url(${props.Background_Image.url})` };
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="valuation-Banner"
          style={background}
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={bgImageZoom}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="">
                  <motion.div
                    className="valuation-heading"
                    variants={fadeInFromBottom}
                  >
                    <h1>{props.Title}</h1>
                    <p>{props.Description}</p>
                  </motion.div>
                  <div className="valuation-block"> 
                    {
                      props.Valuation && props.Valuation.map((valuation, i) => {

                        return (
                          <motion.div
                            className="valuation-box"
                            key={i}
                            custom={i}
                            variants={delayItemsFromBottom}
                          >
                            <h2>{valuation.Title}</h2>
                            <span>{valuation.Description}</span>
                            {valuation.Valuation_Points &&
                              HTMLReactParser(valuation.Valuation_Points.replace(/<br\s*\/?>/gi, ''))
                            }
                            {valuation.CTA_Label &&
                              <PropertyValuationLink
                                valuation={valuation}
                              />
                            }
                          </motion.div>
                        )
                      })
                    } 
                  </div>
                </div>
              </Col>
            </Row> 
          </Container>
        </motion.div>
      )}
    </InView>
  )
}



export default PropertyValuation