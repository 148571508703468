import React, { useState, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import LocRatingMap from '../map/loc-rating-map';
import { MapMarkerInfoBox } from '../map/map-marker';
import GoogleMapReact from 'google-map-react';
import MarkerIcon from '../../images/marker.png';
import  closeIcon  from '../../images/close.png';
import { Link } from "@StarberryUtils";
import { Popover, OverlayTrigger, Card, Button } from 'react-bootstrap'; 
 
const Marker = ({ data, key }) => {
    const [show, setShow] = useState(false);
    const target = React.useRef(null);
    return (
        <OverlayTrigger
        placement="auto"
        target={target.current}
         show={show} 
            overlay={
                <Popover id={`popover`} className="popover-m">
                    <Popover.Content className="p-0">
                        <Card style={{ minWidth: "100%" }} className="p-0 position-relative border-0">
                            {data.Image &&
                                <img src={data.Image} />
                            }
                             <Card.Body>
                                <Card.Title className="mb-1 title">{data.Title}</Card.Title>
                                <Link to={"/contact/" + data.url} className="btn btn-primary">View Office</Link>
                            </Card.Body>
                            <div className="close-btn" onClick={() => setShow(false)}>
                              x
                            </div>
                        </Card>
                    </Popover.Content>
                </Popover>
            }
        >
            <Button ref={target} onClick={() => setShow(!show)} style={{ pointerEvents: "all" }} className="map-btn">
               <img src={MarkerIcon} width="30" height="30" />
            </Button>
        </OverlayTrigger>
    )
}

const OfficeMap = ({ data }) => { 
    const [render, setRender] = useState(false);
    const [places, setPlaces] = useState([]);
    useEffect(() => {
        if (!render, places.length === 0) {
            setPlaces(data.map(c => ({ lat: c.Latitude, lng: c.Longitude, Title: c.Name, Image: c.Tile_Image?.url, url: c.URL })))
            setRender(true)
        }

    })
    const apiIsLoaded = (map, maps, places) => {
        // Get bounds by our places
        const bounds = getMapBounds(map, maps, places);
        // Fit map to bounds
        map.fitBounds(bounds);
        // Bind the resize listener
        bindResizeListener(map, maps, bounds);
    };
    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };
    const getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds();

        places.forEach((place) => {
            bounds.extend(new maps.LatLng(
                place.lat,
                place.lng,
            ));
        });
        return bounds;
    }; 
    return (
        <>
            {render &&
                <div style={{ height: '100vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCAVnlP4tP_CDzlIQ3sl2sB4Bof-4oqxCw' }}  
                        defaultZoom={10}
                        defaultCenter={{
                            lat: 59.95,
                            lng: 30.33
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
                    >

                        {places.map((place, i) => (
                            <Marker
                                lat={place.lat}
                                lng={place.lng}
                                data={place}
                            />
                        )
                        )}
                    </GoogleMapReact>
                </div>
             

            }
        </>
    )
}





export default OfficeMap
