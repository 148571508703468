import { Link } from "@StarberryUtils";
import React, { useState } from "react"
import HTMLReactParser from 'html-react-parser';
import { GetURL } from "../Common/site/functions"
// import Agent01 from "../../images/static/agent.png"
// import Agent02 from "../../images/static/agent02.png"
import { useStaticQuery, graphql } from "gatsby";
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../Forms/react-us-form";
import _ from "lodash"

// Style
import { fadeInFromRight } from '../Utils/animations';
import { motion } from "framer-motion";

const Sidebar = (props) => {

  const data = useStaticQuery(graphql`
    query {
        glstrapi {
          globalModule { 
            Static_Page_Sidebar_Content 
            Static_Page_Sidebar_CTA_Label
            Static_Page_Sidebar_CTA_Link{
              id
            }
            Static_Page_Sidebar_Peoples {
              URL
              Designation
              offices{
                Name
              }
              Name
              Email
              Phone
              Tile_Image {
                url
                alternativeText
              }
            }
          }
        }
    }
`);

  const content = data.glstrapi.globalModule;
  const officeName = (item) => {
    if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
      return _.join(_.map(item.offices, 'Name'), ' & ');
    }
  }
  const [people, setPeople] = useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const closeContactformModal = () => {
    setContactformOpen(false);
  }

  const openContactformModal = (people, peopleNumber) => {
    setPeople(people)
    setPeopleNumber(peopleNumber)
    setContactformOpen(true);
  }
  return (
    <motion.div className="block-sticky overflow-hidden" variants={fadeInFromRight}>
      <div className="help">
        {props.type == "static" && content.Static_Page_Sidebar_Content &&
          <>
            {HTMLReactParser(content.Static_Page_Sidebar_Content.replace(/<br\s*\/?>/gi, ''))}
            {/* <a href="tel:01952291722" className="static-contact mar-bottom"><i className="icon-phone"></i> 01952 291722 </a> */}
          </>

        }
        {props.type == "area_guide" &&
          <>
            <h2>Need help? Speak with our {props.area} team</h2>
            <p>Speak with one of our advisors today for a personal touch</p>
            {/* <a href="tel:01952291722" className="static-contact"><i className="icon-phone"></i> 01952 291722 </a> */}
          </>
        }

        {content.Static_Page_Sidebar_CTA_Label && content.Static_Page_Sidebar_CTA_Link &&
          <Link to={`/${GetURL(content.Static_Page_Sidebar_CTA_Link.id)[0]}`} className="btn btn-primary">{content.Static_Page_Sidebar_CTA_Label}<i className="icon-arrow"></i></Link>
        }

        {content.Static_Page_Sidebar_Peoples && content.Static_Page_Sidebar_Peoples.map((item, i) => {
          return (
            <div className="agent-info" key={i}>
              <div className="agent-img">
                <img src={item.Tile_Image.url} alt={`${item.Name} - DBRoberts`} />
              </div>
              <div className="agent-details">
                <h3>{item.Name}</h3>
                <span className="profile">{officeName(item)} {item.Designation}</span>
                {/* <a
                  href={`javascript:void(0)`}
                  className="team-member-email"
                  onClick={() => { openContactformModal(item.Name, item.Phone) }}
                >
                  {item.Email}
                </a> */} 
                {item.Phone &&
                  <a href={`tel:+44${item.Phone}`} className="tel">{item.Phone}</a>
                }

              </div>
            </div>
          )
        })}
      </div>
      <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
      </ModalTiny>
    </motion.div>
  )
}



export default Sidebar