import React from "react"
// import HTMLReactParser from 'html-react-parser';

// Style
import { fadeInFromRight } from '../Utils/animations';
import { motion } from "framer-motion";

const MagazineSidebar = (props) => {
  return (
    <motion.div className="block-sticky overflow-hidden" variants={fadeInFromRight}>
      <div className="help included-sidebar">
        <ul>
          {props.points && props.points.map((item, index) => {
            return (
              <li key={index}>{item.Add}</li>
            )
          })
          }
        </ul>


      </div>
    </motion.div>
  )
}



export default MagazineSidebar