import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Col } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { getUser } from "@myaccount/website-service"
//import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery/dist/jquery.min.js'
import loadable from '@loadable/component'
import _ from "lodash"
import {removeNonNumeric} from '../Calculators/mortgagecalc/util'


import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

const AddressComponent = loadable(() => import('./elements/address-search'))
const Valpal = loadable(() => import('./valpal-result'))

function InstantValuationForm(props) {

  const userData = getUser();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [showResult, setResult] = useState(false);
  const [errortext, setErrorText] = useState("Highlighted fields are required");

  const [formvalues, setFormvalues] = useState("");

  const [addressLabel, setAddressLabel] = useState('')
  const [addressBuildingName, setAddressBuildingName] = useState('')
  const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
  const [addressPostalCode, setAddressPostalCode] = useState('')
  const [addressSubBuilding, setAddressSubBuilding] = useState('')
  const [addressStreet, setAddressStreet] = useState('')

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  useEffect(() => {
    setAddress();
    
    // setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
    // setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
    // setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
    // setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
    // setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')
  }, []);


  const setAddress = ()=>{
    if(!_.isEmpty(sessionStorage.getItem('addressBuildingName'))){
      setAddressBuildingName(sessionStorage.getItem('addressBuildingName'));
    }else{
      setAddressBuildingName(''); 
    }
    if(!_.isEmpty(sessionStorage.getItem('addressBuildingNumber'))){
      setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber'));
    }else{
      setAddressBuildingNumber(''); 
    }
    if(!_.isEmpty(sessionStorage.getItem('addressPostalCode'))){
      setAddressPostalCode(sessionStorage.getItem('addressPostalCode'));
    }else{
      setAddressPostalCode(''); 
    }
    if(!_.isEmpty(sessionStorage.getItem('addressSubBuilding'))){
      setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding'));
    }else{
      setAddressSubBuilding(''); 
    }
    if(!_.isEmpty(sessionStorage.getItem('addressStreet'))){
      setAddressStreet(sessionStorage.getItem('addressStreet'));
    }else{
      setAddressStreet(''); 
    }
  }


  const fields = ([
    {
      element: "config",
      formname: "Valuation",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. We will be in touch with you shortly.",
      email_temp_user: "valuation_user",
      email_temp_admin: "valuation_admin",
      email_subject_user:"Valuation Request",
      email_subject_admin:"Valuation Request",
      email_server_func: "valuation",
      event_tracking: "valuation",
      page_url: "/valuation"
    },
    {
      grpmd: "10",
      label: "First Name",
      placeholder: "Your Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "steptwo",
      defaultValue: userData?.name || ``
    },
    {
      grpmd: "col-md-2",
      name: "<i className='right-arrow'></i>",
      type: "button",
      element: "button",
      value: "submit",
      step: "steptwo",
      class: "btn btn-primary",
      formclass: ""
    },  
    {
      grpmd: "10",
      label: "Email Address",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: "",
      step: "stepthree",
      defaultValue: userData?.email || ``
    },
    {
      grpmd: "col-md-2",
      name: "<i className='right-arrow'></i>",
      type: "button",
      element: "button",
      value: "submit",
      step: "stepthree",
      class: "btn btn-primary",
      formclass: ""
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "stepfour",
      defaultValue: userData?.mobile || ``
    },
    {
      grpmd: "col-md-12",
      name: "Reveal Valuation",
      type: "submit",
      element: "button",
      value: "submit",
      step: "stepfour",
      class: "btn btn-primary",
      formclass: ""
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.target.name === "telephone"){
      event.target.value = removeNonNumeric(event.target.value);
    } else{
      event.target.value = event.target.value.trimStart()
    }
    
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file

    var d = document.getElementById('custom-file');
    //alert(d.value);
    $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));

    setFile(fileItem); // storing file 
  };


  useEffect(() => {

    // bsCustomFileInput.init()
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;
        let formData = new FormData();
        formvalues['extra'] = JSON.stringify({
          formname: formvalues.formname,
          address: formvalues.address, 
          type: formvalues.type, 
        });
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()

          // lets send mail
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if(props.title != ""){
          fields[0].formname = props.title
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        // setThankyou(true);
        setResult(true)
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }

    $('#valForm').on('keyup keypress', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) { 
        e.preventDefault();
        return false;
      }
    });

  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      var valPhone = $("input[name=telephone]").val();
      var phoneNum = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var telvalidation = false;
      if (valPhone.length >= 10) {
        // console.log("telvalidation 1", valPhone, valPhone.match(phoneNum));
        telvalidation = true
      } else {
        // console.log("telvalidation 2", valPhone, valPhone.match(phoneNum));
        telvalidation = false
      }

      console.log("telvalidation", telvalidation);
      if (telvalidation === false) {
        setErrorText("Please enter valid phone number");
        //$("input[name=telephone]").val('')
      } else{

      }

      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      $('.valuation-step-form .stepone').hide();
      $('.valuation-step-form .steptwo').hide();
      $('.valuation-step-form .stepthree').hide();
      $('.valuation-step-form .stepfour').hide();

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();

    }
  };

  const nextStep = (step) => {
    setShowerror(false);
    setValidated(false);
    if (step === 2) {
    
      var valaddr = $("input[name=address]").val();
      var buildingName = $("input[name=addressStreet]").val();
      console.log(valaddr.length, buildingName.length)
      if (valaddr.length === 0 || buildingName.length === 0) {  
        setShowerror(true);
        setValidated(true);
        setErrorText("Please enter your valid property address with building number")
        $("input[name=address]").val('')
        //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }  
      if (valaddr.length > 0 && buildingName.length > 0) {
        $('.valuation-step-form .stepone').hide();
        $('.valuation-step-form .steptwo').show();
        setAddress();
        // setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
        // setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
        // setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
        // setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
        // setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')
      }

    } else if (step === 3) {

      var valName = $("input[name=name]").val();
      if (valName.length === 0) {
        setShowerror(true);
        setValidated(true);
        setErrorText("Please enter your name")
        $("input[name=name]").val('')
      }
      if (valName) {
        $('.valuation-step-form .stepone').hide();
        $('.valuation-step-form .steptwo').hide();
        $('.valuation-step-form .stepthree').show();
      }
    } else if (step === 4) {
      var valEmail = $("input[name=email]").val();
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var emailvalidation = false;
      if (valEmail.match(mailformat)) {
        emailvalidation = true
      } else {
        emailvalidation = false
      }
      if (valEmail && emailvalidation == true) {
        $('.valuation-step-form .stepone').hide();
        $('.valuation-step-form .steptwo').hide();
        $('.valuation-step-form .stepthree').hide();
        $('.valuation-step-form .stepfour').show();
      } else {
        setShowerror(true);
        setValidated(true);
        setErrorText("Please enter valid email address")
        $("input[name=email]").val('')
      }
    }
    else if (step === 5) {
      var valPhone = $("input[name=telephone]").val();
      var phoneNum = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var telvalidation = false;
      if (valPhone.length >= 10) {
        telvalidation = true
      } else {
        telvalidation = false
      }
      if (valPhone && telvalidation == true) {
        $('.valuation-step-form .stepone').hide();
        $('.valuation-step-form .steptwo').hide();
        $('.valuation-step-form .stepthree').hide();
        $('.valuation-step-form .stepfour').hide();
        //$('.valuation-step-form').submit(); 
        console.log(myRef)
        // myRef.form.getDOMNode().dispatchEvent(new Event("submit"));
      } else {
        setShowerror(true);
        setValidated(true);
        setErrorText("Please enter valid phone number");
        $("input[name=telephone]").val('')
      }
    }
  }

  const backStep = (step) => {
    if (step === 1) {
      $('.valuation-step-form .stepone').show();
      $('.valuation-step-form .steptwo').hide();
      $('.valuation-step-form .stepthree').hide();
      $('.valuation-step-form .stepfour').hide();
      setResult(false)
    }
  }

  return (
    <div>
      <div ref={myRef} />

      {showerror && <div className="alert alert-danger alert-error">
        <p>{errortext}</p>
      </div>}

      {showthankyou && <div className="alert alert-success">
        <p>{fields[0].success_text}</p>
      </div>}

      {showResult && <div className="">
        <Valpal addressBuildingName={addressBuildingName} addressBuildingNumber={addressBuildingNumber} addressPostalCode={addressPostalCode} addressSubBuilding={addressSubBuilding} addressStreet={addressStreet} backStep={backStep}/>
      </div>}

      <Form className="form valuation-step-form" id="valForm" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="type" value={props.type} />
        <input type="hidden" name="bot-field" />
        <input id="addressLabel" value="" type="hidden" name="addressLabel" />
        <input id="addressBuildingName" value="" type="hidden" name="addressBuildingName" />
        <input id="addressBuildingNumber" value="" type="hidden" name="addressBuildingNumber" />
        <input id="addressPostalCode" value={addressPostalCode} type="hidden" name="addressPostalCode" />
        <input id="addressSubBuilding" value="" type="hidden" name="addressSubBuilding" />
        <input id="addressStreet" value="" type="hidden" name="addressStreet" />
        <div className="stepone row">
          <Col md={10}>
            <AddressComponent addressLabel={addressLabel} />
          </Col>
          <Col md={2}>
            <Button onClick={() => { nextStep(2) }} variant="" type="button" className="btn-step btn-step-next btn btn-primary arrow-btn"><i className="right-arrow"></i></Button>
          </Col>
        </div>

        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  step={field.step}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  grpmd={field.grpmd}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  nextStep={nextStep}
                  backStep={backStep}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
        </div>
      </Form>


    </div>
  );
}


const ValuationFormPage = (props) => (
  <InstantValuationForm classone={props.classone} title={props.title} type={props.type} />
)

export default ValuationFormPage