import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
// import Logo from '../../../images/icon.png';
import PlayVideo from "../../Play/CustomVideo";
import HTMLReactParser from 'html-react-parser';
import NewsBanner from "../../StaticPage/StaticBanner/NewsBanner"
import Sidebar from "../../Sidebar/Sidebar"
import Breadcrumbs from "../../Footer/Breadcrumb/Breadcrumb";
import News from "./News";
import { GetURL } from "../../Common/site/functions"
import "./News.scss"
import { Link } from "@StarberryUtils";
import _ from "lodash";
import logoBlack from "../../../images/logoblack.svg";
import SEO from "../../seo";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  // InstapaperShareButton,
} from "react-share";

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GET_NEWS_DETAIL = gql`
  query GetNews($url: String!){	
      newsBlogs(sort:"createdAt:asc", where:{URL:$url})  {
        id
        URL
        Title
        Meta_Title
        Meta_Description
        Short_Description
        Publish
        News_Content {
          ... on ComponentModulesContentWithMultipleImages {
            id
            Content
            Images {
              url
              alternativeText
            }
            Title
          }
          ... on ComponentComponentsAddServiceContent {
            CTA_Label
            CTA_Link {
              id
            }
            id
            Description
            ServiceTitle: Title
          } 
          ... on ComponentModulesIntro {
            id
            Intro_Content
            Intro_Title
          }
          ... on ComponentModulesContentWithCollection {
            id
            CTA_Label
            CTA_Link {
              id
            }
            Title
            Sub_Title
          } 
        }
        Meta_Title
        Meta_Description
        Embed_Video_URL
        Date
        Banner_Image {
          url
          alternativeText
        }
        Tile_Image {
          url
          alternativeText
        }
      }
  }
`;

const NewsDetail = (props) => {
  const { loading, error, data } = useQuery(GET_NEWS_DETAIL, { variables: { url: props.slug } });
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  // if (loading) return (<><NewsBanner /><div className="gql-loading-screen"><img src={Logo} alt="" /><p>Loading ...</p></div></>);
  const trackerShare = (event, scl_share_url) => {
    var shareURL = typeof window !== 'undefined' ? window.location.href : ''
    if (scl_share_url) {
      window.open(scl_share_url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareURL,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          {/* <div className="new-loader-anime"></div> */}
          <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
        </div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
      </div>
    </section>
  );

  var newsDetail = null;
  if (!_.isEmpty(data.newsBlogs)) {
    newsDetail = data.newsBlogs[0];
  }
  return (
    <>
      <SEO title={newsDetail.Meta_Title} description={`${newsDetail.Meta_Description} | DB Roberts`} location={props.location} />
      <NewsBanner {...newsDetail} />
      <section className="guide-details">
        <Container>
          <Row>
            <Col xl={8} lg={7}>
              {newsDetail.Banner_Image &&
                <InView {...inViewOptions}>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={containerVariants}
                    >
                      <div className="history news-banner">
                        <div className="banner-img img-zoom">
                          <picture>
                            <source media="(min-width:992px)" srcSet={newsDetail.Banner_Image.url} />
                            <source media="(min-width:768px)" srcSet={newsDetail.Banner_Image.url} />
                            <img src={newsDetail.Banner_Image.url} alt={newsDetail.Banner_Image.alternativeText} />
                            {
                              newsDetail.Embed_Video_URL &&
                              <PlayVideo url={newsDetail.Embed_Video_URL} icon="icon-play" class="play-btn" buttonName="Play" />
                            }
                          </picture>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </InView>
              }
              {newsDetail.News_Content && newsDetail.News_Content.map((each, i) => {
                var ctaLink;
                  if(each.CTA_Link){
                    ctaLink = GetURL(each.CTA_Link?.id)[0];
                  }
                return (
                    <React.Fragment key={i}>
                      {each.__typename === "ComponentModulesIntro" &&
                        <InView {...inViewOptions}>
                          {({ ref, inView }) => (
                            <motion.div
                              className="guide-info"
                              ref={ref}
                              initial="hidden"
                              animate={inView ? "visible" : "hidden"}
                              variants={containerVariants}
                            >
                              <h3>{each.Intro_Title}</h3>
                              {each.Intro_Content &&
                                HTMLReactParser(each.Intro_Content.replace(/<br\s*\/?>/gi, ''))
                              }
                            </motion.div>
                          )}
                        </InView>
                      }
                      {each.__typename === "ComponentModulesContentWithMultipleImages" &&
                        <>
                          <InView {...inViewOptions}>
                            {({ ref, inView }) => (
                              <motion.div
                                className="paragraph"
                                ref={ref}
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={containerVariants}
                              >
                                <div className="history">
                                  <h2>{each.Title}</h2>
                                  {each.Content &&
                                    HTMLReactParser(each.Content.replace(/<br\s*\/?>/gi, ''))
                                  }
                                  <Row>
                                    {each.Images && each.Images.map((image, i) =>
                                      <Col>
                                        <div className="history-img img-zoom">
                                          <picture>
                                            <source media="(min-width:992px)" srcSet={image.url} />
                                            <source media="(min-width:768px)" srcSet={image.url} />
                                            <img src={image.url} alt={image.alternativeText} />
                                          </picture>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </motion.div>
                            )}
                          </InView>
                        </>
                      }
                      {each.__typename === "ComponentComponentsAddServiceContent" &&
                        <InView {...inViewOptions}>
                          {({ ref, inView }) => (
                            <motion.div
                              ref={ref}
                              initial="hidden"
                              animate={inView ? "visible" : "hidden"}
                              variants={containerVariants}
                            >
                              <div className="buy-sell">
                                <div className="info">
                                  <h2>{each.ServiceTitle}</h2>
                                  <p>{each.Description}</p>
                                </div>
                                {each.CTA_Label && each.CTA_Link &&
                                  <div className="buy-btn">
                                    <Link to={`/${ctaLink}`} className="btn btn-primary">{each.CTA_Label}<i className="icon-arrow"></i></Link>
                                  </div>
                                }
                              </div>
                            </motion.div>
                          )}
                        </InView>
                      }
                    </React.Fragment>
                  )
                }
              )}
              <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                  >
                    <div className="social-links">
                      <span className="sm-txt">Share this article:</span>
                      <span className="social-site">
                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                          <i className="icon-twitter"></i>
                        </TwitterShareButton>

                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                          <i className="icon-fb"></i>
                        </FacebookShareButton>

                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                          <i className="icon-linkedln"></i>
                        </LinkedinShareButton>

                        <a href={`javascript:;`} onClick={() => trackerShare('GplusShareButton', 'https://plus.google.com/share?url=' + shareurl)}>
                          <i className="icon-google"></i>
                        </a>

                        <a href={`javascript:;`} onClick={() => trackerShare('InstaShareButton', 'https://www.instagram.com/?url=' + shareurl)}>
                          <i className="icon-insta"></i>
                        </a>

                      </span>
                    </div>
                  </motion.div>
                )}
              </InView>
            </Col>
            <Col xl={4} lg={5}>
              <Sidebar type="static" />
            </Col>
          </Row>
        </Container>
      </section>
      <News newsId={newsDetail.id} />
      <Breadcrumbs tag="details" category="News" subCategory={null} data={[]} page={newsDetail.Title} />
    </>
  )
}
export default NewsDetail