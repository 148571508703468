import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../Forms/react-us-form";
// Images
import BrandLogo from "../../images/logo.svg"
import _ from "lodash"

// import '../Header/Header.scss';
const TeamFooter = (props) => {
  const [modalContactformOpen, setContactformOpen] = React.useState(false);
  const [people, setPeople] = useState("");
  const [peopleNumber, setPeopleNumber] = useState("");
  const closeContactformModal = () => {
    setContactformOpen(false);
  }

  const openContactformModal = (people, peopleNumber) => {
    setPeople(people)
    setPeopleNumber(peopleNumber)
    setContactformOpen(true);
  }


  return (
    <>
      {props && !props.bannerInView &&
        <>
          <div className={`sticky-footer-bottom d-lg-none`} ></div>
          <div className={`property-footer d-lg-none`} >
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="feature-section sticky-footer">
                    <div className="footer-section-btn">
                      {props.data && props.data.Name &&
                        <Button className="btn btn-primary d-md-flex" onClick={() => { openContactformModal(props.data.Name, props.data.Phone) }}>Message {props.data.Name.split(" ")[0]} <i className="icon-arrow"></i></Button>
                      }
                      <Link to={`/property-valuation/home-visit-valuation`} className="btn btn-primary d-md-flex">Book a Valuation<i className="icon-arrow"></i></Link>


                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      }
      <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
      </ModalTiny>
    </>
  )
}



export default TeamFooter