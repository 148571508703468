import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import _ from "lodash";
import { useStaticQuery, graphql } from "gatsby"
import Select from 'react-select';
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';

const TeamHeader = ({ page, teamhandlerSelect, officehandlerSelect, category }) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
	    teamsCategories(sort:"Sort:asc") {
	      Title
	      URL
	    }

	    offices {
	      Name
	      URL
	    }
    }
  }
`);


  const team_categories = data.glstrapi.teamsCategories
  const offices = data.glstrapi.offices

  var options = [{ value: 'all_departments', label: 'All Departments' }];
  _.forEach(team_categories, (cat) => { 
    options.push({ value: cat.URL, label: cat.Title })
  })
  var office_options = [{ value: 'all_offices', label: 'All Offices' }];
  _.forEach(offices, (off_cat) => {
    if(off_cat.URL == "oakengates"){
      office_options.push({ value: 'oakengates', label: 'Lettings' })
    }else{
      office_options.push({ value: off_cat.URL, label: off_cat.Name })
    }
    
  })
  const [selectedOption, setSelectedOption] = useState({ value: 'all_departments', label: 'All Departments' })
  const [officeselectedOption, setOfficeSelectedOption] = useState({ value: 'all_offices', label: 'All Offices' })
  var handleChange = selectedOption => {
    console.log(selectedOption)
    setSelectedOption(selectedOption);
    teamhandlerSelect(selectedOption)
  };
  var officehandleChange = officeselectedOption => {
    setOfficeSelectedOption(officeselectedOption);
    officehandlerSelect(officeselectedOption)
  };

  useEffect(() => { 
    if (category === "lettings") { 
      setSelectedOption({ value: 'lettings', label: 'Lettings' });
    } 

  }, [category]) 
 
  return (
    <Container>
      <Row>
        <Col xs="12">
          <div className="dropdown-filter">
            <Select
              value={officeselectedOption}
              onChange={officehandleChange}
              options={office_options}
              defaultMenuIsOpen={false}
              className={`select-form-control video-select-box`}
              classNamePrefix={`reactSelect2`}
            /> 
            {/* <Select
              value={selectedOption}
              defaultValue
              onChange={handleChange}
              options={options}
              defaultMenuIsOpen={false}
              className={`select-form-control video-select-box`}
              classNamePrefix={`reactSelect2`}
            /> */}
          </div>
        </Col>
      </Row>
    </Container>

  )
}



export default TeamHeader