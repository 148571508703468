import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import "../Home/News/News.scss";
import { Link } from "@StarberryUtils";
import { GetURL } from '../Common/site/functions';
import _ from "lodash";
import "./TeamStories.scss";
import PlayVideo from "../Play/CustomVideo";
import ModalTiny from "react-bootstrap/Modal"
import ReachUsForm from "../Forms/react-us-form";
import logoBlack from "../../images/logoblack.svg";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GET_ALL_TEAMS = gql`
    query GetTeams{	
        teams(where:{Publish:true}) {
            Tile_Image {
                url
                alternativeText
            }
            Sort
            Email
            Designation
            URL
            Video_URL
            id
            Name
            Phone
            offices {
                Name
            }
        }
    }
`;


const TeamStories = (props) => {

    const [people, setPeople] = useState("");
    const [peopleNumber, setPeopleNumber] = useState("");
    const [modalContactformOpen, setContactformOpen] = React.useState(false);
    const closeContactformModal = () => {
        setContactformOpen(false);
    }

    const openContactformModal = (people, peopleNumber) => {
        setPeople(people)
        setPeopleNumber(peopleNumber)
        setContactformOpen(true);
    }


    var settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        arrows: false,
        mobileFirst: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
        ],
    };

    const { loading, error, data } = useQuery(GET_ALL_TEAMS);
    
    // if (loading) return <div className="gql-loading-screen"><p>Loading ...</p></div>;

    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" className="new-loader">
              {/* <div className="new-loader-anime"></div> */}
              <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
            </div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
          </div>
        </section>
    );
    
    const officeName = (item) => {
        if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
            return _.join(_.map(item.offices, 'Name'), ' & ');
        }
    }

    return (
        <section className="news-section">
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.div
                        className="news"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="block-title">
                                        <span>{props.Title}</span>
                                        <h2>{props.Sub_Title}</h2>
                                        <div className="mobile_news_more_btn d-lg-none d-xl-none">
                                            {props.CTA_Link &&
                                                <Link to={GetURL(props.CTA_Link.id)} className="btn btn-outline">{props.CTA_Label}</Link>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="news-box-wrap team-stories">
                                <Slider {...settings}>
                                    {(data && data.teams) && data.teams.map((item, index) => (
                                        <motion.div
                                            className="news-box"
                                            key={index}
                                            custom={index}
                                            variants={delayItemsFromBottom}
                                        >
                                            <div className="img-wrapper img-zoom">
                                                <Link to={`/contact/our-team/${item.URL}`}>
                                                    <picture>
                                                        <source media="(min-width:1200px)" srcSet={item.Tile_Image.url} />
                                                        <source media="(min-width:768px)" srcSet={item.Tile_Image.url} />
                                                        <img src={item.Tile_Image.url} alt= {`${item.Name} ${item.Designation} - DB Roberts`}/>
                                                    </picture>
                                                </Link>
                                                {item.Video_URL &&
                                                    <div className="news-btn">
                                                        <PlayVideo url={item.Video_URL} />
                                                    </div>
                                                }
                                            </div>
                                            <div className="team-member-info">

                                                <Link to={`/contact/our-team/${item.URL}`} className="team-member-name">
                                                    <div className="d-flex align-items-center team-member-hover">
                                                        {item.Name}
                                                        <i className="icon-arrow ml-auto"></i>
                                                    </div>
                                                </Link>

                                                <span className="team-member-designation">
                                                    {officeName(item)} {item.Designation}
                                                </span>
                                                <a
                                                    href={`javascript:void(0)`}
                                                    className="team-member-email"
                                                    onClick={() => { openContactformModal(item.Name, item.Phone) }}
                                                >
                                                    {item.Email}</a>
                                            </div>
                                        </motion.div>
                                    ))}
                                </Slider>
                            </div>
                        </Container>
                    </motion.div>
                )}
            </InView>
            <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {people}</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><ReachUsForm title={`Contact ${people}`} office={""} people_name={people} people_number={peopleNumber} email_temp_user="individual_contact_user" /></ModalTiny.Body>
            </ModalTiny>
        </section>
    )
}
export default TeamStories;