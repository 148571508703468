import { Link } from "@StarberryUtils";
import React from "react"
// Style
import { GetURL } from "../Common/site/functions";

const PropertyValuationLink = ({ valuation }) => (
    <Link to={`/${GetURL(valuation.CTA_Link.id)[0]}`} className="btn btn-primary">{valuation.CTA_Label}</Link>
)

export default PropertyValuationLink
