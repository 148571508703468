import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import TeamDetail from "./MemberDetails/MemberDetails";
import TeamDetailContent from "./ContentBlock/ContentBlock";
import Breadcrumbs from "../Footer/Breadcrumb/Breadcrumb";
import TeamMember from "./TeamMember/TeamMember";
import Logo from '../../images/icon.png';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import _ from "lodash";
import logoBlack from "../../images/logoblack.svg";
import TeamFooter from "../Footer/TeamFooter"
import Footer from "../Footer/Footer"
import { InView } from 'react-intersection-observer';

const GET_TEAM_DETAIL = gql`
  query GetTeam($url: String!){	
      teams(where:{URL:$url})  {
	    Name
	    URL
	    Designation
		offices{
			Name
		}
	    Meta_Title
	    Meta_Description
	    Full_Image {
	      url
	      alternativeText
	    }
	    Email
	    Phone
	    Video_URL
	    About
      }
  }
`;

const TeamDetails = (props) => {
	const [bannerInView, setBannerInView] = useState(true);
	const { loading, error, data } = useQuery(GET_TEAM_DETAIL, { variables: { url: props.slug } });

	//   if (loading) return (<><div className="gql-loading-screen team-details-loading"><img src={Logo} alt="" /><p>Loading ...</p></div></>);

	if (loading) return (
		<section className={"loader-wrapper"}>
			<div id="loader-wrapper">
				<div id="loader" class="new-loader">
					{/* <div className="new-loader-anime"></div> */}
					<img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
				</div>
				<div class="loader-section section-left"></div>
				<div class="loader-section section-right"></div>
			</div>
		</section>
	);

	var teamDetail = null;
	if (!_.isEmpty(data.teams)) {
		teamDetail = data.teams[0];
	}

	return (
		<>
			{_.isEmpty(teamDetail) &&
				<section className={"loader-wrapper"}>
					<div id="loader-wrapper">
						<div id="loader" class="new-loader">
							<div className="new-loader-anime"></div>
							<img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
						</div>
						<div class="loader-section section-left"></div>
						<div class="loader-section section-right"></div>
					</div>
				</section>

			}
			{!_.isEmpty(teamDetail) &&
				<>
					<SEO title={`${teamDetail.Name} ${teamDetail.Designation} | Property Expert`} description={`Meet ${teamDetail.Name} ${teamDetail.Designation} at DB Robots here. The go-to professionals for everything relating to local property buying, selling, renting and property management.`} location={props.location}  />
					<InView as="div" onChange={(inView, entry) => setBannerInView(inView)}>
						<TeamDetail data={teamDetail} />
					</InView>
					<TeamDetailContent data={teamDetail} />
					<TeamMember url={teamDetail.URL} />
					<Breadcrumbs tag="details" category="Contact" subCategory="Our People" data={[]} page={teamDetail.Name} />
					<Footer popularSearch={props.popularSearch} popularSearchType="static" />
					<TeamFooter data={teamDetail} bannerInView={bannerInView} />
				</>
			}
		</>
	)
}

export default TeamDetails