import React from "react"
import {Row, Col} from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser'; 
// Style
import  './OurStory.scss';

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const OurStory = (props) => { 
    return(
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.div variants={containerVariants}>
            <div className="history">
              <h2>{props.Title}</h2>
              {props.Content &&
                HTMLReactParser(props.Content.replace(/<br\s*\/?>/gi, ''))
              }
              <Row>
                {props.Images && props.Images.map((image, i) =>
                  <Col>
                    <div className="history-img img-zoom">
                      <picture>
                        <source media="(min-width:992px)" srcSet={image.url} />
                        <source media="(min-width:768px)" srcSet={image.url} />  
                        <img src={image.url} alt={image.url.alternativeText} />
                      </picture>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </motion.div>
        )}
      </InView>
    )
}



export default OurStory