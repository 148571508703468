import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PlayVideo from "../../Play/CustomVideo";
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment/src/moment";
import axios from "axios";
import _ from "lodash";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionCol = motion(Col)

const YOUTUBE_API = "AIzaSyDCZxSCPj9PZXJU6W28UJ1zCn1Psdr9T7Y";
const CHANNEL_ID = "UCH8xPtLWVF8NlVFqM8XWzQA";

const Videos = (props) => {
    const data = useStaticQuery(graphql`
            query {
                glstrapi {
                    videos (sort:"Sort:asc", where:{Publish:true}){         
                    id
                    Title
                    URL
                    Publish
                    Embed_Video_URL
                    Image {
                        url
                        alternativeText
                    }
                    createdAt 
                    video_categories {
                        URL
                    }
                    }
                }
            }
    `);

    // const videos = data.glstrapi.videos;
    // var filteredVideos = videos;
    // if (props.category !== "all_videos") {
    //     filteredVideos = videos.filter(video => {
    //     let category = video.video_categories.filter(cat => cat.URL === props.category);
    //     return category.length > 0
    //     });
    // }

    const [videos, setVideos] = React.useState([]);
    const getVideosById = async (id) => {
        if (typeof id !== "undefined") {
            let video_list = await axios.get(`https://youtube.googleapis.com/youtube/v3/playlistItems?playlistId=${id}&part=snippet&channelId=${CHANNEL_ID}&maxResults=1000&key=${YOUTUBE_API}`);
            // "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1000&playlistId="+playlist_id+"&key="+YoutubeAPIKey;
            if (video_list && video_list.data && video_list.data.items)
                setVideos(video_list?.data?.items)
        }
    }



    React.useEffect(() => {
        if (props.category) {
            getVideosById(props.category)
        }

    }, [props.category]);

    const ThumbnailsImage  = ({image}) =>{
        var renderImg = null;
        if(!_.isEmpty(image.standard?.url)){
            
            renderImg = <img src={image.standard?.url} alt="Youtube Image" />
        }else if(!_.isEmpty(image.high?.url)){
            renderImg =  <img src={image.high?.url} alt="Youtube Image" />
        } 
        return renderImg
    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="all-news-section videos-section"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <div className="news">
                        <Container>
                            <div className="news-box-wrap">
                                <Row>
                                    {videos.map((item, index) => (
                                        <MotionCol
                                            lg={3}
                                            md={6}
                                            key={index}
                                            custom={index}
                                            variants={delayItemsFromBottom}
                                        >
                                            <div className="news-box">
                                                <div className="properties-wrapper img-zoom video-box">
                                                    {item.snippet?.thumbnails &&
                                                    <ThumbnailsImage image={item.snippet?.thumbnails}/>
                                                    } 
                                                    <div className="banner-btn">
                                                        <PlayVideo url={`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`} />
                                                    </div>
                                                </div>
                                                <div className="newsletter-info">
                                                    <div className="time-info">{moment(item.snippet.publishedAt).format("DD MMMM YYYY")}
                                                    </div>
                                                    <h3>{item.snippet.title}</h3>
                                                </div>
                                            </div>
                                        </MotionCol>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div>
                </motion.section>
            )}
        </InView>
    )
}
export default Videos