import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// import SearchInputBox from '../../Predictive-search/search-inputbox';
import SearchInputBox from '../../SearchResult/SearchList/SearchBox';
import $ from 'jquery/dist/jquery.min.js'
import { navigate } from "@reach/router"
// Style
import './SearchForm.scss';

import { inViewOptions, containerVariants, fadeInFromRight } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionRow = motion(Row);

const SearchForm = (props) => {
  let url = "/";
  useEffect(() => {
    $(".search_btn").click(function () {
      var searcValue = $(".search-box .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = ""
      }
      navigate("/property/for-sale/" + url);
    })
  })

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="search-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <MotionRow variants={fadeInFromRight}>
              <Col xl={8} sm={12}>

                  <div className="search-heading">
                    <span>{props.Title}</span>
                    <h2>{props.Sub_Title} </h2>
                  </div>

              </Col>
              <Col xl={8} sm={12}>
                <Form className="form-data search-form">
                  <div className="search-box w-100">
                    <div className="form-group autosuggest_container">
                      <SearchInputBox areaVal={""}/>
                    </div>
                    {/* <Form.Control type="search" id="search" placeholder="Street, area or postcode" /> */}
                    <i className="icon-search">
                    </i>
                  </div>
                  <button className="btn btn-outline btn-sm search_btn" type="button">Search</button>
                </Form>
              </Col>
            </MotionRow>

          </Container>
        </motion.div>
      )}
    </InView>
  )
}



export default SearchForm