import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

const SocialWall = () => {
    return (
        <>
            <Helmet>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
            <Container className="social-wall mb-4">
                 <div className="elfsight-app-b7582716-acec-4277-b0e7-0aebb9b82bce"></div>
            </Container>
        </>
    )
}

export default SocialWall;