import { Link } from "@StarberryUtils";
import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { GetURL } from "../../Common/site/functions";
import { useMediaQuery } from 'react-responsive'

// Style
import './PropertyList.scss';

import { inViewOptions, containerVariants, fadeInFromRight, fadeInFromLeft } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PropertyManagement = (props) => {
    const [isDesktop, setIsDesktop] = useState(true);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    });

    useEffect(() => {
        setIsDesktop(isDesktopOrLaptop)
    }, [isDesktopOrLaptop])

 
    return (
        <section className={`property-management ${isDesktop}`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <InView {...inViewOptions}>
                            {({ ref, inView }) => (
                                <motion.div
                                    className="about-content"
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={containerVariants}
                                >
                                    <span className="sm-content">{props.ServiceTitle}</span>
                                    <p>{props.Description}</p>
                                </motion.div>
                            )}
                        </InView>
                    </Col>
                    {props.Add_Service_Content && props.Add_Service_Content.map((service, i) => {
                        var url = "";
                        if (service.CTA_Link) {
                            url = GetURL(service.CTA_Link.id)[0]; 
                            if(url === "contact/our-team"){
                                url = url + '#lettings';
                            }
                        }

                        return (
                            <Fragment key={i}>
                                <Col md={12} lg="6" className={`property-list`}>
                                    <InView {...inViewOptions}>
                                        {({ ref, inView }) => (
                                            <motion.div
                                                ref={ref}
                                                initial="hidden"
                                                animate={inView ? "visible" : "hidden"}
                                                variants={containerVariants}
                                            >
                                                {i % 2 == 0 || isDesktop == false ?
                                                    <motion.div
                                                        className="management-info img-zoom"
                                                        variants={fadeInFromLeft}
                                                    >
                                                        <picture>
                                                            <source media="(min-width:992px)" srcSet={service.Image.url} alt={service.Image.alternativeText} />
                                                            <source media="(min-width:768px)" srcSet={service.Image.url} alt={service.Image.alternativeText} />
                                                            <img src={service.Image.url} alt={service.Image.alternativeText} />
                                                        </picture>
                                                    </motion.div>
                                                    :
                                                    <motion.div
                                                        className="management-details"
                                                        variants={fadeInFromRight}
                                                    >
                                                        <h2>{service.Title}</h2>
                                                        <p>{service.Description}</p>
                                                        {service.CTA_Label && service.CTA_Link &&
                                                            <div className="btn-wrapper">
                                                                <Link to={`/${url}`} className="btn btn-outline">{service.CTA_Label}</Link>
                                                            </div>
                                                        }
                                                    </motion.div>
                                                }
                                            </motion.div>
                                        )}
                                    </InView>
                                </Col>
                                <Col md={12} lg={6} className="property-list">
                                    <InView {...inViewOptions}>
                                        {({ ref, inView }) => (
                                            <motion.div
                                                ref={ref}
                                                initial="hidden"
                                                animate={inView ? "visible" : "hidden"}
                                                variants={containerVariants}
                                            >
                                                {i % 2 == 0 || isDesktop == false ?
                                                    <motion.div
                                                        className="management-details"
                                                        variants={fadeInFromRight}
                                                    >
                                                        <h2>{service.Title}</h2>
                                                        <p>{service.Description}</p>
                                                        {service.CTA_Label && service.CTA_Link &&
                                                            <div className="btn-wrapper">
                                                                <Link to={`/${url}`} className="btn btn-outline">{service.CTA_Label}</Link>
                                                            </div>
                                                        }
                                                    </motion.div>
                                                    :
                                                    <motion.div
                                                        className="management-info img-zoom"
                                                        variants={fadeInFromLeft}
                                                    >
                                                        <picture>
                                                            <source media="(min-width:992px)" srcSet={service.Image.url} alt={service.Image.alternativeText} />
                                                            <source media="(min-width:768px)" srcSet={service.Image.url} alt={service.Image.alternativeText} />
                                                            <img src={service.Image.url} alt={service.Image.alternativeText} />
                                                        </picture>
                                                    </motion.div>
                                                }
                                            </motion.div>
                                        )}
                                    </InView>
                                </Col>
                            </Fragment>)
                    })}
                </Row>
            </Container>
        </section>

    )
}

export default PropertyManagement