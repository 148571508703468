import React, { useState, Fragment } from "react";
import LoadExternalScript from "../Utils/load-external-script";
import { Container, Row, Col } from 'react-bootstrap';

const locRatingScriptsJs = 'https://api.feefo.com/api/javascript/dbroberts';

const Feefo = (props) => {
  return (
    <>
      <LoadExternalScript
        src={locRatingScriptsJs}
        // async="true"
        defer="true"
      // loadScript={renderMap}
      // appendScriptTo="head"
      />
      <Container>
        <div className="feefo-review">
        <div id="feefo-service-review-carousel-widgetId" class="feefo-review-carousel-widget-service"></div>
        </div>
      </Container>
    </>
  )
}

export default Feefo