import { Link } from "@StarberryUtils";
import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
// Style
import './StaticBanner.scss';

import {
  // EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  // EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

import { inViewOptions, containerVariants, contentItemStagger, contentItem } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionCol = motion(Col)

const NewsBanner = (props) => {
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  const [Shareicons, setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  const trackerShare = (event) => {
    var shareURL = typeof window !== 'undefined' ? window.location.href : ''
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' + shareURL,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
    });
}

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <MotionCol variants={contentItemStagger}>
                <div className="banner-content">
                  {props.Title &&
                    <motion.h2 variants={contentItem}>{props.Title}</motion.h2>
                  }
                  {props.Short_Description &&
                    <motion.p variants={contentItem}>{props.Short_Description}</motion.p>
                  }
                  {props.Title &&
                    <motion.div className="banner-section-btn news-banner" variants={contentItem}>
                      <Link to="/property-valuation/home-visit-valuation" className="btn btn-primary">Book a Valuation<i className="icon-arrow"></i></Link>
                      <Link to="/property/for-sale" className="btn btn-primary">Find a Property<i className="icon-arrow"></i></Link>

                      <div >
                        <a href={"javascript:;"} onClick={()=>{setShareicons(!Shareicons)}}  className="btn-circle  d-xl-none d-sm-none"> <i className="icon-share"></i>
                          {Shareicons &&
                            <div className="property-share-social-icons">
                              <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                              </FacebookShareButton>
                              <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                              </TwitterShareButton>
                              <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                              </LinkedinShareButton>
                              <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                              </WhatsappShareButton>
                            </div>
                          }
                        </a>
                      </div>
                    </motion.div>
                  }
                </div>
              </MotionCol>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}



export default NewsBanner