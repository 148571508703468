import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
// import PlayVideo from "../../Play/PlayVideo";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
// import Logo from '../../../images/icon.png';
import { limitedContent } from '../../Common/site/functions';
import { LATEST_NEWS_PAGE_URL } from '../../Common/site/constants';
import "./AllNews.scss"
import { Link } from "@StarberryUtils";
import moment from "moment/src/moment";
import logoBlack from "../../../images/logoblack.svg";
import _ from "lodash";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GET_ALL_NEWS = gql`
  query GetNews{	
      newsBlogs(sort:"createdAt:asc", where:{Publish:true})  {
        id
        URL
        Title
        Short_Description
        Publish 
        Date 
        Tile_Image {
          url
          alternativeText
        }
      }
  }
`;

const AllNews = () => {
  const { loading, error, data } = useQuery(GET_ALL_NEWS);

  // if (loading) return <div className="gql-loading-screen"><img src={Logo} alt="" /><p>Loading ...</p></div>;

  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          {/* <div className="new-loader-anime"></div> */}
          <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
        </div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
      </div>
    </section>
  );

  var NewsList = _.cloneDeep(data?.newsBlogs);
  if (!_.isEmpty(NewsList)) {
    NewsList.map((news) => {
      news['timestamp'] = moment(news.Date).format("X");
    })
    NewsList.sort(function (x, y) {
      return y.timestamp - x.timestamp;
    })
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="all-news-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="news">
            <Container>
              <div className="news-box-wrap">
                <Row>
                  {NewsList.map((item, index) => (<Col lg={3} md={6} key={index}>
                    <motion.div className="news-box" variants={delayItemsFromBottom} custom={index}>
                      <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${item.URL}`}>
                        <div className="properties-wrapper img-zoom">
                          <picture>
                            <source media="(min-width:1200px)" srcSet={item.Tile_Image.url} />
                            <source media="(min-width:768px)" srcSet={item.Tile_Image.url} />
                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                          </picture>

                        </div>
                      </Link>
                      <div className="newsletter-info">
                        <div className="time-info">{moment(item.Date).format("DD MMMM YYYY")}
                        </div>
                        <h3> <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${item.URL}`}>{limitedContent(item.Title, 80)}</Link> </h3>
                      </div>
                    </motion.div>
                  </Col>
                  ))}
                </Row>
              </div>
            </Container>

          </div>
        </motion.section>
      )}
    </InView>
  )
}
export default AllNews