import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Modules from "../components/modules";
import SEO from "../components/seo";
import "../scss/custom.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { lazyLoader, appendScript } from "../components/Common/site/functions"
import $ from "jquery";
import "../scss/custom2.scss";

const DefaultTemplate = (props) => {
  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;
  const [render, setRender] = useState(true);
  const [lazyLoad, setLazyLoad] = useState(true);
  useEffect(() => {
    //setRender(true)
    // lazyLoader(window, () => {
    scriptInsert()
    // })
  }, [])


  const videoAsk = () => {
    if (GQLPage.Show_Video_Ask) {
      window.videoask.loadEmbed({
        "kind": "widget",
        "url": "https://www.videoask.com/fkqt7g8yd",
        "options": {
          "widgetType": "VideoThumbnailExtraLarge",
          "text": "",
          "backgroundColor": "#22044E",
          "position": "bottom-right",
          "dismissable": false
        }
      })
    } else {
      var element = $('.videoask-embed__button--nnvG1');
      if (element) {
        element.remove()
      }
    }
  }
  const scriptInsert = () => {
    // if (document.getElementById("bugherd") === null) {
    //   var bugherdScript = document.createElement('script');
    //   bugherdScript.id = 'bugherd';
    //   bugherdScript.async = true;
    //   bugherdScript.type = 'text/javascript';
    //   bugherdScript.src = 'https://www.bugherd.com/sidebarv2.js?apikey=8kuhioryb1xxdxsejb1jog';
    //   document.getElementsByTagName('body')[0].appendChild(bugherdScript);
    // }
    if (document.getElementById("chat") === null) {
      appendScript('https://www.videoask.com/embed/embed.js', "chat", () => {
        videoAsk()
      });
    } else {
      videoAsk()
    }
  } 

  return (
    <>
      {render &&
        <div>
          <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} location={props.location} />
          <Modules page={GQLPage} modules={Page_Modules} {...props} lazyLoad={lazyLoad} />
        </div>
      }
    </>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
      query defaultQuery($id: ID!) {
        glstrapi {
          article(id: $id, publicationState: LIVE) {
            _id
            Pagename
            Meta_Description
            Meta_Title
            Layout
            Banner_Image {
              url
              alternativeText
            }
            Alias
            Page_Title_SM
            Banner_Video_URL
            CTA_1_Label
            CTA_2_Label
            CTA_1_Form
            CTA_2_Form
            Custom_CSS_Class
            Show_News_Form
            Show_Sidebar
            Show_Page_Title_SM
            Show_Video_Ask
            Show_Youtube_Form
            Sub_Title
            Title
            Popular_Search
            Modules {
              ... on GLSTRAPI_ComponentModulesIntro {
                CTA_Label
                Intro_Content
                Intro_Title
                Sidebar
                _id
                CTA_Link {
                  id
                  URL
                }
              }
              ... on GLSTRAPI_ComponentModulesContentWithList {
                Bottom_Content
                CTA_Label
                Sub_Title
                Title
                Top_Content
                _id
                Tile_Image {
                  url
                  alternativeText
                }
                CTA_Link {
                  id
                  URL
                }
                Content_with_Image {
                  Sub_Content
                  Title
                  Title_as_Link
                  _id
                  CTA_Link {
                    URL
                    id
                  }
                  Icon {
                    url
                    alternativeText
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesValuationBlock {
                id
                Description
                Title
                Background_Image {
                  url
                }
                Valuation {
                  CTA_Label
                  CTA_Link {
                    id
                  }
                  Valuation_Points
                  Title
                  Description
                }
              }
              ... on GLSTRAPI_ComponentModulesContentWithCollection {
                CTA_Label
                Title_With_Collection: Select_Collection
                Sub_Title
                Place
                Title
                _id
                CTA_Link {
                  URL
                  id
                }
              }
              ... on GLSTRAPI_ComponentModulesServiceTiles {
                id
                Add_service_Tile {
                  Description
                  Image {
                    alternativeText
                    url
                  }
                  link {
                    id
                  }
                  Label
                  Video_URL
                }
              }
              ... on GLSTRAPI_ComponentModulesGoogleReviews {
                id
                Reviews_Background_Image {
                  alternativeText
                  url
                }
              }
              ... on GLSTRAPI_ComponentModulesServiceContentBlock {
                id
                ServiceTitle: Title
                Description
                Add_Service_Content {
                  CTA_Label
                  CTA_Link {
                    id
                  }
                  Title
                  Description
                  id
                  Image {
                    url
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesForms {
                id
                Description
                Form_Name: Name
                Form_Title: Title
                Background_Image {
                  url
                }
                People_in_Sidebar {
                  URL
                  Designation
                  Name
                  Phone
                  Tile_Image {
                    url
                    alternativeText
                  }
                  offices {
                    Name
                  }
                }
              }
              ... on GLSTRAPI_ComponentModulesSelectCollection {
                id
                Collection: Select_Collection
              }
              ... on GLSTRAPI_ComponentModulesFooterBlock {
                id
                CTA_1_Label
                CTA_2_Label
                CTA_1_URL {
                  URL
                  id
                }
                CTA_2_URL {
                  URL
                  id
                }
                Title
                type: __typename
              }
              ... on GLSTRAPI_ComponentModulesContentWithMultipleImages {
                id
                Content
                Title
                Images {
                  url
                }
                type: __typename
              }
              ... on GLSTRAPI_ComponentModulesMap {
                id
                Latitude
                Longtitude
                type: __typename
              }
              ... on GLSTRAPI_ComponentModulesContentWithContactPerson {
                id
                CTA_Label
                CTA_Form
                Block_Content
                Sidebar_Content
                Block_Title
                CTA_Link {
                  id
                }
                People_to_Contact {
                  Name
                  URL
                  Designation
                  Phone
                  Email
                  Tile_Image {
                    alternativeText
                    url
                  }
                  offices {
                    Name
                  }
                }
              }
            }
            Choose_Menu {
              Label
              Main_Parent {
                Label
                id
                URL
              }
              Sub_Parent {
                Label
                URL
                id
              }
            }
            Header_CTA_1_Link {
              id
            }
            Header_CTA_2_Link {
              id
            }
          }
        }
    }
      `