import { Link } from "@StarberryUtils";
import React, { useState } from "react";
import PlayVideo from "../../Play/CustomVideo";
import HTMLReactParser from 'html-react-parser';

import { delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";

const OfficeList = (props) => {

  const [isPlay, setPlay] = useState(false);

  return (
    <motion.div variants={delayItemsFromBottom} custom={props?.custom}>
      <div className="gallery-img img-zoom">
        {props.videoid &&
          <div className="gallery-pic">
            {props.GalleryImg &&
              // <picture>
              //   <source media="(min-width:992px)" srcSet={props.GalleryImg.url} />
              //   <source media="(min-width:768px)" srcSet={props.GalleryImg.url} />
                <img src={props.GalleryImg.url} className="p-0" alt={`${props.GalleryName} Office - Dacha`} />
              // </picture>
            }

            <div className="banner-btn">
              <PlayVideo url={props.videoid} />
            </div>
          </div>
        }
        {!props.videoid &&
          <Link to={`/contact/${props.url}`}>
            <div className="gallery-pic">
              {props.GalleryImg &&
              //   <picture>
              //     <source media="(min-width:992px)" srcSet={props.GalleryImg.url} />
              //     <source media="(min-width:768px)" srcSet={props.GalleryImg.url} />
                  <img src={props.GalleryImg.url} className="p-0"  alt={`${props.GalleryName} Office - Dacha`}/>
              //   </picture>
              }
            </div>
          </Link>
        }
        <div className="gallery-content">
          <h2><Link to={`/contact/${props.url}`}>{props.GalleryName}</Link></h2>
          <span className="mail_address">
            {props.Address &&
              HTMLReactParser(props.Address)
            }
          </span>

          <span className="tel ">
            <a className="tel-no" href={`tel:+44${props.tel}`}>
              {props.tel}
            </a>
          </span>
          <Link to={`/contact/${props.url}`} className="slider">
            <i className="icon-slide"></i>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default OfficeList;
