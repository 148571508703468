// import { Link } from "gatsby";
import React, { useEffect } from "react";
// import Bannerimg from "../../../images/static/mob-img01.png";
// import Bannerimg02 from "../../../images/static/mob-img02.png";
// import BannerTab01 from "../../../images/static/tab-img01.png";
// import BannerTab02 from "../../../images/static/tab-img02.png";
// import BannerDesk01 from "../../../images/static/desk-img01.png";
// import BannerDesk02 from "../../../images/static/desk-img02.png";
// import BannerDesk03 from "../../../images/static/desk03.png";
// import BannerDesk04 from "../../../images/static/desk04.png";
import { isMobile } from "../../jsutility/index";
import { Container, Row, Col } from "react-bootstrap";
import CardsList from "./CardsList";
import { v4 as uuidv4 } from "uuid";
import { isTablet, isIPad13, isIOS13 } from 'react-device-detect';
// Style
import "./ServiceCards.scss";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionCol = motion(Col);

const ServiceCards = (props) => { 
 
  let intialNumberOfReview = 4;

  useEffect(() => {

    if (isMobile()) {
      intialNumberOfReview = 2;
    }

    else if (isTablet || isIPad13 || isIOS13) {
      intialNumberOfReview = 2
    }
  }, []);

  useEffect(() => {
    
  }, []);

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="static-gallery"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <div className="gallery-wrapper">
              <Row>
                {props.list.map((item, index) => (
                  <MotionCol
                    lg="3"
                    md="6"
                    sm="6"
                    key={index}
                    className="gallery-wrapper-grid"
                    variants={delayItemsFromBottom}
                    custom={index}
                  >
                    <CardsList
                      Image={item.Image.url}
                      Label={item.Label}
                      Description={item.Description}
                      Link={item.link}
                      unkey={uuidv4()}
                      VideoURL={item.Video_URL}
                    />
                  </MotionCol>
                ))}
              </Row>
            </div>
          </Container>
        </motion.section>
      )}
    </InView>
  );
};
export default ServiceCards;
