import { Link } from "@StarberryUtils";
import React, { useState } from "react";
import PlayVideo from "../../Play/CustomVideo";
import { GetURL } from "../../Common/site/functions";
import _ from 'lodash';

import { delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";

const CardsList = (props) => {
    const [isPlay, setPlay] = useState(false);
    let url = "";
    if (!_.isEmpty(props.Link)) {
        url = GetURL(props.Link.id);
    } else {
        url = props.url;
    }
    return (
        <React.Fragment>
            <motion.div variants={delayItemsFromBottom} custom={props?.custom}>
                <div className="gallery-img img-zoom">
                    {typeof url === "object" &&<div className="gallery-pic">
                        {props.Link && url[1] == 'Internal' ?
                            <Link to={`/${url[0]}`}>
                                <img src={props.Image} className="p-0" alt={props.Image.alternativeText} />
                            </Link> :
                            <a href={`${url ? url[0] : ''}`} target="_blank">
                                <img src={props.Image} className="p-0" alt={props.Image.alternativeText} />
                            </a>
                        }
                        {props.VideoURL &&
                            <div className="banner-btn ff">
                                <PlayVideo url={props.VideoURL} />
                            </div>
                        }
                    </div>}
                    {typeof url === "string" &&<div className="gallery-pic">
                        <Link to={`/${url}`}>
                            <img src={props.Image} className="p-0" alt={props.Image.alternativeText} />
                        </Link>
                        {props.VideoURL &&
                            <div className="banner-btn">
                                <PlayVideo url={props.VideoURL} />
                            </div>
                        }
                    </div>}
                    {typeof url === "object" && <div className="gallery-content">
                        {props.Link && url[1] == 'Internal' ?
                            <Link to={`/${url[0]}`}> <h2> {props.Label}</h2></Link> :
                            <a href={`${url ? url[0] : ''}`} target="_blank"> <h2> {props.Label}</h2></a>
                        }
                        {props.Description && <span className="main-desc">{props.Description}</span>}
                        {props.Link && url[1] == 'Internal' ?
                            <Link to={`/${url[0]}`} className="slider">
                                <i className="icon-slide"></i>
                            </Link>
                            :
                            <a href={`${url ? url[0] : ''}`} className="slider" target="_blank"><i className="icon-slide"></i></a>
                        }

                    </div>}
                    {typeof url === "string" &&
                        <div className="gallery-content">
                            <Link to={`/${url}`}> <h2> {props.Label}</h2></Link>
                            {props.Description && <span className="main-desc">{props.Description}</span>}
                            <Link href="#" className="slider">
                                <i className="icon-slide"></i>
                            </Link>
                        </div>}
                </div>
            </motion.div>
        </React.Fragment>
    );
};

export default CardsList;
