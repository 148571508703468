import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Nav, Tab } from 'react-bootstrap';
// import { calculateMonthlyPayment } from "../../calculator/mortgage";
import { filterNumber, numberFormat, pmt, calculateMonthlyPayment } from "../Calculators/mortgagecalc/util"
import { addCommas, removeNonNumeric, numericWithDecimal, CalculatorFields } from "../Common/utils";
import ModalTiny from "react-bootstrap/Modal"
import RequestForm from "../Forms/request-form";
import Select from 'react-select';

import { inViewOptions, fadeInFromTop, bgImageZoom } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import './Calculator.scss';
const styles = {
    control: styles => ({ ...styles, borderRadius: '40px',  fontSize: '1rem', color: '#263140', minHeight: '45px', paddingLeft: '1rem', marginBottom: '1rem' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#263140',
      }
    },
    menu: (provided, state) => ({
      ...provided,
     zIndex:99
    }),
}
// Style

const durationOptions =[
    { value: 10, label: "10 Years"},
    { value: 15, label: "15 Years"},
    { value: 20, label: "20 Years"},
    { value: 25, label: "25 Years"},
    { value: 30, label: "30 Years"},
    { value: 35, label: "35 Years"}
 ]

const ValuationTwo = (props) => {
    const [calculator_value, setCalculatorValue] = useState({ 'loan_amount': "", 'interest_rate': 2.5, 'amortization': 25, 'down_payment': '0' });
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [modalRequestformOpen, setRequestformOpen] = React.useState(false);
    const [duration, setDuration] = React.useState({ value: 25, label: "25 Years"})
    const [error, setError] = useState("");

    const handleChange = event => {
        if (event.target.name === "interest_rate") {
            setCalculatorValue({ ...calculator_value, [event.target.name]: event.target.value > CalculatorFields.InterestSliderMax ? CalculatorFields.InterestSliderMax : numericWithDecimal(event.target.value) })
        } else if (event.target.name === "down_payment") {
            //  setCalculatorValue({ ...calculator_value, [event.target.name]: calculator_value.loan_amount < removeNonNumeric(event.target.value) ? 1 : removeNonNumeric(event.target.value) })
            setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) }) 
            if (parseInt(calculator_value.loan_amount) < parseInt(removeNonNumeric(event.target.value))) {
                setError("Deposit Payment is greater than the Property Price")
     
            }else{
                setError("")
            }
            setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) })
        } else {
            if (event.target.name === "amortization") {
                if (parseInt(event.target.value) > 35) {

                } else {
                    setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) })
                }
            } else {
                setCalculatorValue({ ...calculator_value, [event.target.name]: removeNonNumeric(event.target.value) })
            }
        }
    }

    const handleDuration = ({ label, value }) => {
        setDuration({ label, value });
        if (parseInt(value) > 35) {

        } else {
            setCalculatorValue({ ...calculator_value, amortization: removeNonNumeric(value) })
        }
    }

    


    const closeRequestformModal = () => {
        setRequestformOpen(false);
    }

    const openRequestformModal = () => {
        setRequestformOpen(true);
    }

    useEffect(() => { 
      
        let result = calculateMonthlyPayment(calculator_value.loan_amount, calculator_value.interest_rate, filterNumber(calculator_value.down_payment), calculator_value.amortization);    
 
        setMonthlyPayment(numberFormat(Math.round(result))) 
    }, [calculator_value]); 
 
    return (
        <React.Fragment>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.div
                        className="valuation-form mortgage-calc"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={bgImageZoom}
                    >

                        <Container>
                            <Row>
                                <Col lg="12">
                                    <motion.div
                                        className="valuation-form-block"
                                        variants={fadeInFromTop}
                                    >
                                        <div className="valuation-form-heading">
                                            <h1>{props.Form_Title}</h1>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit lipsum.</p> */}
                                        
                                        </div>
                                        <div className="valuation-form-tabing">
                                        {error && <div className="alert alert-danger alert-error">
                                                <p>{error}</p>
                                            </div>}
                                            <div id="left-tabs-example" defaultActiveKey="first">
                                                <div className="valuation-block">
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Label>Property Price(£)</Form.Label>
                                                            <Form.Control
                                                                maxlength={CalculatorFields.PriceInputMax}
                                                                value={addCommas(calculator_value.loan_amount)}
                                                                name="loan_amount"
                                                                placeholder="Enter the Property Price"
                                                                onInput={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Deposit(£)</Form.Label>
                                                            <Form.Control
                                                                maxlength={CalculatorFields.PriceInputMax}
                                                                value={addCommas(calculator_value.down_payment)}
                                                                name="down_payment"
                                                                onInput={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Repayment Term(years)</Form.Label>
                                                            {/* <Form.Control
                                                                maxlength={CalculatorFields.YearInputMax}
                                                                value={calculator_value.amortization}
                                                                name="amortization"
                                                                onInput={handleChange}
                                                            /> */}
                                                            <Select
                                                                name="amortization"
                                                                value={duration}
                                                                options={durationOptions}
                                                                onChange={handleDuration}
                                                                placeholder="Terms"
                                                                styles={styles}
                                                                className={`customselectbox`}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Interest Rate(%)</Form.Label>
                                                            <Form.Control
                                                                maxlength={CalculatorFields.InterestInputMax}
                                                                value={calculator_value.interest_rate}
                                                                name="interest_rate"
                                                                onInput={handleChange}
                                                            />
                                                        </Form.Group>
                                                        <div className="form-block-text">
                                                            <label className="form-label">Your Monthly Payment</label>
                                                            <div className="price-block">
                                                                <span className="price-text">£ {monthlyPayment}</span>
                                                                <small>per month</small>
                                                            </div>
                                                            <h2>Ready to talk with one mortgage advisor?</h2>
                                                        </div>
                                                        <Button variant="primary" onClick={() => { openRequestformModal() }} className="btn">Contact our advisor</Button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.div>
                )}
            </InView>
            <ModalTiny size="lg" show={modalRequestformOpen} onHide={closeRequestformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact our advisor</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><RequestForm title="Contact our advisor" /></ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>

    )
}



export default ValuationTwo