import { Link } from "@StarberryUtils";
import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
// import Agent01 from "../../../images/static/agent.png"
// import Agent02 from "../../../images/static/agent02.png"
import ConveyancingForm from "../../Forms/conveyancing-form"
import _ from "lodash"


// Style
import './RequestForm.scss';

import { inViewOptions, containerVariants, fadeInFromRight } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const RequestForm = (props) => {
    const officeName = (item) => {
        if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
            return _.join(_.map(item.offices, 'Name'), ' & ');
        }
    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="request-form"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <Row>
                            <Col>
                                <div className="about-content">
                                    <span className="sm-content">{props.Form_Title}</span>
                                    <p>{props.Description}</p>
                                </div>
                                <div className="our-info">
                                    <div className="send" id="send-request">
                                        <ConveyancingForm title={props.Form_Title} />
                                    </div>
                                    <motion.div
                                        className="team-info"
                                        variants={fadeInFromRight}
                                    >
                                        <h2>Speak with our team</h2>
                                        <p>Speak with one of our advisors today for a personal touch.</p>
                                        <div className="about-agent">
                                            {props.People_in_Sidebar && props.People_in_Sidebar.map((item, i) => {
                                                return (
                                                    <div className="agent-info">
                                                        <Link className="agent-img" to={`/contact/our-team/${item.URL}`}>
                                                            <img src={item.Tile_Image.url} alt={`${item.Name} ${item.Designation} - DB Roberts`} />
                                                        </Link>
                                                        <div className="agent-details">
                                                            <h3><Link to={`/contact/our-team/${item.URL}`}>{item.Name}</Link></h3>
                                                            <span className="profile">{officeName(item)} {item.Designation}</span>
                                                            {item.Phone &&
                                                                <a href={`tel:+44${item.Phone}`} className="tel">{item.Phone}</a>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </motion.div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}

export default RequestForm