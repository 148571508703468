import { useStaticQuery, graphql } from "gatsby"
import React from "react";
// import Bannerimg from "../../../images/static/mob-img01.png";
// import Bannerimg02 from "../../../images/static/mob-img02.png";
// import BannerTab01 from "../../../images/static/tab-img01.png";
// import BannerTab02 from "../../../images/static/tab-img02.png";
// import BannerDesk01 from "../../../images/static/desk-img01.png";
// import BannerDesk02 from "../../../images/static/desk-img02.png";
// import BannerDesk03 from "../../../images/static/desk03.png";
// import BannerDesk04 from "../../../images/static/desk04.png";
// import { isMobile, isMobileTab } from "../../jsutility/index";
import { Container, Row, Col } from "react-bootstrap";
import OfficeList from "./OfficeList";
import OfficeMap from "../../map/office_map";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
// import { isTablet, isIPad13, isIOS13 } from 'react-device-detect';
// Style
import "./OfficeGallery.scss";

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const OfficeGallery = (props) => {

  const data = useStaticQuery(graphql`
  query {
      glstrapi {
        offices (sort:"Sort:asc", where: {Publish: true}){         
            Name
            URL
            Tile_Image {
              alternativeText
              url
            }
            Latitude
            Longitude
            Video_URL
            Address
            Mobile
          }
        }
  }
`);

  const officeList = data.glstrapi.offices;
  return (
    <>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="static-gallery"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
              <div className="gallery-wrapper">
                <Row>
                  {officeList.map((item, index) => (
                    <Col className="cardList" lg={3} sm={6} xs={12} key={index}>
                      <OfficeList
                        GalleryImg={item.Tile_Image}
                        GalleryName={item.Name}
                        Address={item.Address}
                        Street={item.Street}
                        Location={item.Location}
                        house={item.house}
                        tel={item.Mobile}
                        unkey={uuidv4()}
                        videoid={item.Video_URL}
                        url={item.URL}
                        custom={index}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </motion.section>
        )}
      </InView>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="OfficeMap"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <OfficeMap data={_.filter(officeList, off => off.Name != "Head Office")}/>
          </motion.section>
        )}
      </InView>
    </>
  );
};
export default OfficeGallery;
