import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import CardsList from "./ServiceCards/CardsList";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag"
import { isTablet, isIPad13, isIOS13 } from 'react-device-detect';
import { isMobile } from "../jsutility";
import { useQuery } from '@apollo/client';

// Style
import "./ServiceCards/ServiceCards.scss";
import AreaGuideMap from "../map/area-gudes-map";

import { inViewOptions, containerVariants } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const GET_ALL_AREA_GUIDES = gql`
    query GetAreaGuides{	
        areaGuides {
            Tile_Image {
                url
                alternativeText
            }
            Title
            URL
            Embed_Video_URL
            Area_Sub_Title
            Latitude
            Longitude
        }
    }
`;

const AreaGuideList = (props) => { 
    let intialNumberOfReview = 4;

    useEffect(() => {
        if (isMobile()) {
            intialNumberOfReview = 2;
        } else if (isTablet || isIPad13 || isIOS13) {
            intialNumberOfReview = 2
        }
    }, []);
    const { loading, error, data } = useQuery(GET_ALL_AREA_GUIDES); 
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="static-gallery"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <div className="gallery-wrapper area-guide-list">
                            <Row>
                                {(data && data.areaGuides) && data.areaGuides.map((item, i) => (
                                    <Col lg="3" md="6" sm="6" key={i} className="cardList">
                                        <CardsList
                                            Image={item.Tile_Image.url}
                                            Label={item.Title}
                                            Description={item.Description}
                                            url={"about/area-guides/" + item.URL}
                                            unkey={uuidv4()}
                                            VideoURL={item.Embed_Video_URL}
                                            custom={i}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>
                
                    {(data && data.areaGuides) && 
                        <AreaGuideMap
                            data={data.areaGuides}
                        />
                    }
                </motion.section>
            )}
        </InView>
    );
};
export default AreaGuideList;
