export const youtube_parser =(url) => {
  var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = url.match(regExp);
  return (match && match[1].length==11)? match[1] : false;
}

export const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export  const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";
export  const numericWithDecimal = num => num ? num.toString().replace(/[^0-9\.]+/g, "") : "";

export const stb_sale_link =  process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/property-for-sale";
export const stb_rent_link =  process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/property-to-rent";
export const stb_commercial_link =  process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/commercial-property";

export const numberOnly = (value) => {
    if(value){
        if(!/^[0-9]+$/.test(value)){
        //alert("Please only enter numeric characters.")
            return "Please enter  only numeric characters.";
        }
    }  
}

export const CalculatorFields = {
    PriceSliderMax: 99999999,
    PriceInputMax: 10,
    InterestSliderMax: 15,
    InterestInputMax: 5,
    YearSliderMax: 35,
    YearInputMax: 2,
    DownPaymentSliderMax: 15,
    DownPaymentInputMax: 2,
    FeesSliderMax: 99999,
    FeesInputMax: 6,
}
