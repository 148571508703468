import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import BookValuationForm from '../Forms/valuation-form';
import InstantValuationForm from '../Forms/instant-valuation-form'

import { inViewOptions, fadeInFromTop, bgImageZoom } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const ValuationForm = (props) => {
  const [ReadMoreText, ReadMoreLess] = useState(false);

  const ReadMore = () => {
    ReadMoreLess(!ReadMoreText)
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="valuation-form"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={bgImageZoom}
        >
          <Container>
            <Row>
              <Col lg="12">
                <motion.div className="valuation-form-block" variants={fadeInFromTop}>
                  <div className="valuation-form-heading">
                    <h1>{props.Form_Title}</h1>
                    <p>{props.Description}</p>
                  </div>
                  <div className="valuation-form-tabing">
                    {props.Form_Name === "Book_Your_Valuation" &&
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav variant="pills" className="flex-column flex-md-row justify-content-md-between">
                          <Nav.Item>
                            <Nav.Link eventKey="first">I want to Sell</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">I want to Let</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="valuation-block">
                              <BookValuationForm title={props.Form_Title} type="Sales" />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="valuation-block">
                              <BookValuationForm title={props.Form_Title} type="Lettings" />
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    }
                    {props.Form_Name === "Instant_Valuation" &&
                      <InstantValuationForm  title="Instant Valuation" />
                    } 
                    {props.Form_Name === "Book_A_Valuation" &&
                      <BookValuationForm title="Book a Valuation" type="Sales" />
                    } 
                  </div>
                </motion.div>
              </Col>
            </Row>

          </Container>
        </motion.div>
      )}
    </InView>
  )
}



export default ValuationForm