import { Link } from "@StarberryUtils";
import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import CareerForm from "../../Forms/career-form"
import _ from "lodash"

// Style
import './CareerForm.scss';

import { inViewOptions, fadeInFromTop } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const CareerFormPage = (props) => {
    const [file, setFile] = React.useState(null);
    const fileRef = React.createRef(null);
    const officeName = (item) => {
        if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
            return _.join(_.map(item.offices, 'Name'), ' & ');
        }
    }
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="contact-page overflow-hidden"
                    id="career-form"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={fadeInFromTop}
                >
                    <Container>
                        <Row>
                            <Col>
                                <div className="about-content">
                                    <span className="sm-content">
                                        {props.Form_Title}
                                    </span>
                                    <p>{props.Description}</p>
                                </div>
                                <div className="our-info">
                                    <div className="send">
                                        <CareerForm title={`Career Form`}/> 
                                    </div>
                                    <div className="team-info career-sidebar">
                                        <h2>Speak with our team</h2> 
                                        <div className="about-agent">
                                            {props.People_in_Sidebar && props.People_in_Sidebar.map((item, index) => (
                                                <div className="agent-info">
                                                    <Link to={`/contact/our-team/${item.URL}`} className="agent-img">
                                                        <img src={item.Tile_Image.url} alt= {`${item.Name} ${item.Designation} - DB Roberts`} />
                                                    </Link>
                                                    <div className="agent-details">
                                                        <h3><Link to={`/contact/our-team/${item.URL}`}>{item.Name}</Link></h3>
                                                        <span className="profile">{officeName(item)} {item.Designation}</span>
                                                        <a className="tel-no" href={`tel:+44${item.Phone}`}>{item.Phone}</a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}

export default CareerFormPage