import * as React from "react"
import { Container, Row, Col } from "react-bootstrap" 
import Slider from "react-slick"; 
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "@StarberryUtils";
import { limitedContent, GetURL } from '../../Common/site/functions';
import { MAGAZINE_PAGE_URL } from '../../Common/site/constants';
import moment from "moment/src/moment";
import _ from "lodash";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";


const OtherMagazine = (props) => { 

  const data = useStaticQuery(graphql`
  query {
      glstrapi {
          magazines(sort: "Created_Date:desc") {
            id
            About_Magazine
            Created_Date
            Tile_Image {
              url
              alternativeText
            }
            Featured
            Title
            URL
            Magazine_Major_Points {
              Add
            }
          }
        }
  }
`);



  const magazines = data.glstrapi.magazines; 
  var  MagList = magazines;
  if (!_.isEmpty(props.magId)) {
    MagList = magazines.filter(list => list.id != props.magId);
  } 

  var settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: (MagList.length > 4)? true : false,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    className: "center",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,

        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }; 

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="news-section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="news">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="block-title">
                    <h2>Other insights that may interest you</h2>
                  </div>
                </Col>
              </Row>
              <div className="news-box-wrap">
                <Slider {...settings}>
                  {MagList && MagList.map((item, index) => (
                    <motion.div
                      className="news-box"
                      key={index}
                      custom={index}
                      variants={delayItemsFromBottom}
                    >
                      <Link to={`${MAGAZINE_PAGE_URL.alias}/${item.URL}`}>
                        <div className="properties-wrapper img-zoom">
                          <picture>
                            <source media="(min-width:1200px)" srcSet={item.Tile_Image.url} alt={item.Tile_Image.alternativeText}/>
                            <source media="(min-width:768px)" srcSet={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText}  />
                          </picture>

                        </div>
                      </Link>
                      <div className="newsletter-info">
                        <div className="time-info">{moment(item.Created_Date).format("DD MMMM YYYY")}
                        </div>
                        <h3> <Link to={`${MAGAZINE_PAGE_URL.alias}/${item.URL}`}>{limitedContent(item.Title, 80)}</Link> </h3>
                      </div>
                    </motion.div>
                  ))}
                </Slider>  
              </div>
            </Container> 

          </div>
        </motion.section>
      )}
    </InView>
  )
}
export default OtherMagazine